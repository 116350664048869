.contenedorFecha{
    margin-top: 50px;
    display:flex;
    align-items: center;
    justify-content: center;
}
.flechaAtras, .flechaAdelante{
    height: 50px;
    width: 50px;
    /*filter: invert(0%) sepia(00%) saturate(4000%) blur(0px);*/
  
    cursor:pointer;
}
.flechaAtras:hover, .flechaAdelante:hover{
    height: 50px;
    width: 50px;
    filter: invert(0%) sepia(50%) saturate(4000%) blur(0px);
    cursor:pointer;
}

.fecha{
    
    text-align: center;
    border-radius: 10px;
    background-color: rgb(182, 82, 165);
    border:0px;
    color:white;
    margin-left:10px;
    margin-right: 10px;
    font-size: 20px;
    width: 150px;
}