a{
    text-decoration:none;
    color: white;
}

.fondoFicha{
    display:flex;
    margin-bottom: 10px;
    text-align: center;
    /*background-color: rgba(255, 255, 255, 0.5);*/
    background-color: #e00eaa;
    color: white;
    border-radius: 10px;
    margin: 10px;
   
    
}

.infoFicha{
    top:0px;
    bottom: 0px;
    margin: auto;
    
}

.wpicon{
    width: 20px;
}