.barra-menu{
    background-color: #B652A5;
    height: 40px;
    width: 100%;
    position: fixed;
    display:grid;
    grid-template-columns: 40px auto 40px;
    z-index: 5;
}
.btn-menu{
    width: 40px;
    height: 40px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.btn-X{
    color: white;
    width: 40px;
    height: 40px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.nombre-usuario{
    color:white;
    display:flex;
    align-items: center;
    margin-left: 20px;
}
.img-usuario{
    display:flex;
    justify-content: right;
    width: 40px;
    height: 40px;
    border-radius: 20px;
}
.line{
    background-color: white;
    height: 3px;
    width: 25px;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    border-radius: 10px;
}


.ventanaMenu{
    /*display: none;*/
    top:40px;
    position:fixed;
    background-color: #B652A5;
    width: 100%;
    opacity: 0.9;
}
.ventanaMenu hr{
    margin: 0px;
}

.ventanaMenu li{
    list-style: none;
    color: white;
    margin-top: 10px;
    cursor: pointer;  
    height: 30px; 
}