
.fondo-calendario{
    margin-top: 40px;
    background-color: white;
}

.botones-calendario{
    display: flex;
    justify-content: center;
    align-items: center;
}
.botones-calendario button{
    border: none;
    background-color: white;
    height: 40px;
    width: 50px;
    font-size: large;
    cursor:pointer;
}
.botones-calendario div{
    display: flex;
    border: none;
    background-color: white;
    height: 40px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: large;
    justify-content: center;
    align-items: center;
}

.dias-calendario{
    text-align: center;
    display:grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    
}

.dias-num-calendario{
    display:grid;
    grid-template-columns: auto auto auto auto auto auto auto;
}
.dias-num-calendario .button{
    border: none;
    background-color: white;
    height: 40px;
    font-size: large;
    cursor: pointer;
}
.selected{
    border: none;
    background-color: rgb(253, 89, 89);
    height: 40px;
    font-size: large;
    cursor: pointer;
}
.activo{
    border: none;
    background-color: pink;
    height: 40px;
    font-size: large;
    cursor: pointer;
}
.d0{
   grid-column-start:1; 
   
}
.d1{
    grid-column-start:2; 
    
 }
 .d2{
    grid-column-start:3;
   
 }
 .d3{
    grid-column-start:4; 
   
 }
 .d4{
    grid-column-start:5; 
  
 }
 .d5{
    grid-column-start:6; 
  
 }
 .d6{
    grid-column-start:7; 

 }


 .ventana-habilitar{
    width: 100%;
    height: 100px;
 }


 
.form-citas{
    display:grid;
    grid-template-columns: auto;
    align-items: center;
    justify-content: center;
    background-color: #e00eaa;
    border-radius: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

input[type="checkbox"]{
    display: none;
}

label{
    padding: 10px;
    padding-left: 25px;
    position: relative;
    text-align: center;
    color: white;
}

label:before{
    content:"";
    border:solid 2px white;
    background-color: red;
    border-radius: 3px;;
    position: absolute;
    width: 20px;
    height:20px;
    left: 0px;
    
}


[type="checkbox"]:checked +label:before{
    background-color: green;
    
 }