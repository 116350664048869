.h1{
    width: 100%;
    height: 50px;
    position:sticky;
    top: 0px;
    right: inherit;
    background-color:#282828;
    text-align: center;

}
.fondo-adb{
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #282828;
    color:white;
    width: 100%;
    height: 100%;
}
.fondo-adb hr{
    margin-top: 0px;
    margin-bottom: 0px;

}
.ficha{
    background-color: #3C3C3C;
    margin: 10px;
    padding-bottom: 5px;
   
    border-radius: 10px;
}
.ficha p{
    margin-left: 10px;
   
}
.botonera-ficha{
    display:flex;
    align-items:center;
    justify-content:right;
}
.botonera-ficha button{
    background-color: white;
    border-radius: 0 10px 0 0;
    width: 100px;
    height: 30px;
    border: 0px;
    font-size: large;
}
.ventana-editar{
    position: absolute;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: red;
    width: 100px;
    height: 50px;
}