.qr-div{
    display: grid;
    align-items: center;
    margin-top: 50px;
    width: 100%;
    height: 256px;
    justify-content: center;
}
.indicacion-QR{
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #B652A5;
    color:white;
    text-align: center;
    font-size: 20px;
    opacity: 0.9;
}
.fondo-enlace-QR{
    background-color: white;
    color:black;
    text-align: center;
    font-size: 13px;
    opacity: 0.9;
    border-radius: 30px;
    padding-top:20px;
    padding-bottom:20px;
    display:grid;
    grid-template-columns: auto auto;
    align-items: center;
}
.btn-copy{
    font-size: 10px;
    width: 35px;
    height: 35px;
    padding: 0px;
}
   
