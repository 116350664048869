
.App {
  width: 100%;
  text-align: center;
}
.content{
  position:absolute;
  width: 100%;
}

.imgFondo{
  position:fixed;
  order: -1;
  width:100%;
  height:100%;
  
}