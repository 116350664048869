.fnd{
    display:grid;
    align-items: center;
    margin-top: 100px;
}
.fondo-cargando{
    padding-top: 100px;
    display:inline-block;
    position: absolute;
    top:150px;
    background-color: white;
    height: 150px;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
}
.fondo-iniciar{
    padding-top: 100px;
    display:inline-block;
    position: absolute;
    top:150px;
    background-color: white;
    height: 150px;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    
}
.fondo-crear{
    display:inline-block;
    top:150px;
    background-color: white;
    padding-bottom: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    z-index: 2;
}
.form-nombre{
    
    margin-top: 0px-;
    display:grid;
    grid-template-columns: 200px;
    justify-content: center;
  
}
.form-nombre input{
    text-align: center;
}
.form-nombre p{
    margin: 0px;
    text-align: center;
}

.msj-name{
    color:red;
    font-size: 12px;
    margin:0px;
}

.btn{
    margin-top: 20px;
    background-color: #ed154e;
    color:white;
    border:none;
    height: 30px;
    border-radius: 10px;
}